import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import { createStyles, makeStyles } from "@mui/styles";
import { CircularProgress, Theme } from "@mui/material";
import { Box } from "@mui/material";
import { BodyPix } from "@tensorflow-models/body-pix";
import { useEffect, useState } from "react";
import { BackgroundConfig } from "../helpers/backgroundHelper";
import { PostProcessingConfig } from "../helpers/postProcessingHelper";
import { SegmentationConfig } from "../helpers/segmentationHelper";
import { SourceConfig, SourcePlayback } from "../helpers/sourceHelper";
import { TFLite } from "../hooks/useTFLite";
import OutputViewer from "./OutputViewer";

type ViewerCardProps = {
  sourceConfig: SourceConfig;
  backgroundConfig: BackgroundConfig;
  setLocalStream: any;
  localCam: any;
  sourcePlayback: any;
  segmentationConfig: SegmentationConfig;
  postProcessingConfig: PostProcessingConfig;
  bodyPix?: BodyPix;
  tflite?: TFLite;
};

function ViewerCard(props: ViewerCardProps) {
  const classes = useStyles();
  const [sourcePlayback, setSourcePlayback] = useState<SourcePlayback>(
    props.sourcePlayback
  );
  // console.log("the source playback object is", props.sourcePlayback);
  useEffect(() => {
    // console.log(
    //   "the source playback object in useeffect is",
    //   props.sourcePlayback
    // );
    setSourcePlayback(props.sourcePlayback);
  }, [props.sourcePlayback]);

  return (
    // <Box
    //   style={{
    //     position: "relative",
    //     minWidth: "34vw",
    //     minHeight: "34vh",
    //     marginLeft: "12px",
    //     marginBottom: "12px",
    //   }}
    // >
    <>
      {
        sourcePlayback && props.bodyPix && props.tflite ? (
          <OutputViewer
            sourcePlayback={sourcePlayback}
            backgroundConfig={props.backgroundConfig}
            segmentationConfig={props.segmentationConfig}
            postProcessingConfig={props.postProcessingConfig}
            bodyPix={props.bodyPix}
            tflite={props.tflite}
            setLocalStreamVb={props.setLocalStream}
          />
        ) : (
          <CircularProgress />
        )
        // (
        //   <div className={classes.noOutput}>
        //     <Avatar className={classes.avatar} />
        //   </div>
        // )
      }
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => {
  const minHeight = [`${theme.spacing(52)}px`, `100vh - ${theme.spacing(2)}px`];

  return createStyles({
    root: {
      // minHeight: `calc(min(${minHeight.join(", ")}))`,

      display: "flex",
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",

      [theme.breakpoints.up("md")]: {
        // gridColumnStart: 1,
        // gridColumnEnd: 3,
      },

      [theme.breakpoints.up("lg")]: {
        // gridRowStart: 1,
        // gridRowEnd: 3,
      },
    },
    noOutput: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    avatar: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  });
});

export default ViewerCard;
