import {
    UPDATE_CONF_OPTIONS
} from "../actions/types";

const initialState = {
    bottomOptions: []
       
};
const confOptionsReducer = (state = initialState, action) =>
{
    switch (action.type) {
        case UPDATE_CONF_OPTIONS:
            return {
                ...state,
                bottomOptions:action.payload
            };
        default:
            return state;
    }
}
export default confOptionsReducer;