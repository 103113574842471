import axios from "axios";

const urlCall = async (url, type, data, cb) => {
  // axios.defaults.withCredentials = true;
  let response = await axios({
    method: type,
    url,
    data,
  }).catch((error) => {
    console.error(error);
    return null;
  });

  // console.log("url called = "+url);
  // console.log("type = "+type);
  // console.log("data = "+JSON.stringify(data));
  // console.log("axos.default = "+axios.defaults.headers.common['Authorization']);

  // console.log("response.data = ",response.data);

  if (response?.data?.success) {
    // handle response
    // console.log("response = "+JSON.stringify(response.data));
    if (!cb) return response?.data;
    else cb(null, response?.data);
  } else {
    // handle error
    // if (!cb) return response.data.error;
    // {
    // }
    if (!cb) {
      return response?.data;
    }
    // console.error("err = " + JSON.stringify(response.data.error));
    else cb(response?.data?.error);
  }
};

export default urlCall;
