import {
SET_SITE_SETTING
}
from './types'

  export const fetchSiteSetting = (siteSetting) => {
    return {
      type: SET_SITE_SETTING,
      payload: siteSetting
    }
  }
