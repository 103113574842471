import React, { Component, Fragment, useState, useEffect } from "react";
import { Redirect, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import fscreen from "fscreen";
import styles from "./navigation.module.scss";
import { connect } from "react-redux";
import {
  setMenu,
  setTheme,
  setFullScreen,
} from "../../actions/connectionActions";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Hidden,
  Menu,
  MenuItem,
  Select,
  Avatar,
  Tooltip,
  Paper,
  Chip,
  ListItemIcon,
  Divider,
  Button,
  Badge,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import makeStyles from "@mui/styles/makeStyles";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import SettingsIcon from "@mui/icons-material/Settings";
import logo from "../../images/favicon.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import Person from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LoginIcon from "@mui/icons-material/Login";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import BugReportIcon from "@mui/icons-material/BugReport";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenIconExit from "@mui/icons-material/FullscreenExit";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useMediaQuery, useTheme } from "@mui/material";
import { withRoomContext } from "../ConferenceRoom/RoomContext";

// actions
import { landingPageSetting } from "../../actions/LandingPageSettingActions";
import ConsoleHelper from "../../utils/consoleHelper";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";
import { MSG_SENDER_TYPE_MODERATOR } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  toolBar: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolBarLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));

const Navigation = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const mobileMenu = useSelector((state) => state.connection.mobileMenu);
  const browser = useSelector((state) => state.connection.browser);
  const switchTheme = () => {
    props.theme === "light" ? props.setTheme("dark") : props.setTheme("light");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-account-menu";
  const linkColor = props.theme === "light" ? styles.black : styles.white;
  const linkStyle = [styles.link, linkColor].join(" ");
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [hour, setHour] = useState("00");
  const [callStartTimeLocal, setCallStartTimeLocal] = useState(0);
  const [counter, setCounter] = useState(0);
  const fullScreen = useSelector((state) => state.connection.fullScreen);
  const roomLockStatus = useSelector((state) => state.conf.roomLockStatus);
  const participantType = useSelector((state) => state.conf.participantType);

  const setFullScreenStatus = (status) => {
    dispatch(setFullScreen(status));
  };
  useEffect(() => {
    let intervalId;
    if (props.callStartTime !== 0) {
      // ConsoleHelper('navigation props.callstarttime and calltimelocal', `${props.callStartTime} - ${callStartTimeLocal}`)
      if (props.callStartTime !== callStartTimeLocal) {
        setCallStartTimeLocal(props.callStartTime);
        let callTimeTillYet = Math.round(
          (Date.now() - props.callStartTime) / 1000
        );
        // ConsoleHelper('navigation callTimetillyet', callTimeTillYet);
        setCounter(counter + callTimeTillYet);
      }
    }
    if (props.showCallTimer) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter =
          Math.floor(counter % (60 * 60)) === 0
            ? "0"
            : Math.floor(counter / 60) - 60 * Number(hour);
        const hourCounter = Math.floor(counter / (60 * 60));

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;
        const computedHour =
          String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);
        setHour(computedHour);
        // console.log(`time lapsed is-${hour}:${minute}:${second}`)
        setCounter((counter) => counter + 1);
        if (counter > props.warningBeforRoomCloseTime) {
          props.showRoomCloseWarning();
        }
        if (counter > Number(props.warningBeforRoomCloseTime) + 60) {
          props.closeRoom();
        }
      }, 1000);
    } else {
      setCounter(0);
      setSecond("00");
      setMinute("00");
      setHour("00");
    }

    return () => {
      clearInterval(intervalId);
      const mainConfElement = document.getElementById("mainConfComponent");
      if (mainConfElement) fscreen.removeEventListener("fullscreenchange");
    };
  }, [props.showCallTimer, counter, props.callStartTime]);

  const handleProfileMenuOpen = (event) => {
    if (fullScreen) toggleFullScreen();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (param) => {
    setAnchorEl(null);

    // handleMobileMenuClose();
  };

  const handleRedirect = (from, link) => {
    if (from === "room") {
      window.open(link, "_blank", "noopener,noreferrer");
      handleMenuClose();
    } else {
      handleMenuClose();
      setTimeout(() => {
        history(link);
      }, 100);
      // history(link);
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      // keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      // onBlur={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 4.5,
          maxWidth: "350px",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            // backgroundColor: "#3f51b5",
            // color: "#fff",
            fontWeight: "bold",
            fontSize: "2rem",
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      sx={{
        "& .MuiListItemIcon-root": {
          color: theme.palette.primary.main,
        },
      }}
    >
      {props.authenticated ? (
        <span>
          <MenuItem
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              cursor: "default",
            }}
          >
            <Avatar
              style={{
                width: "56px",
                height: "56px",
                color: theme.palette.common.blue,
                backgroundColor: grey[200],
              }}
            >
              {props.user.name.slice(0, 1).toUpperCase()}
            </Avatar>
            <Typography variant="h6" gutterBottom>
              {props.user.name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ color: "gray" }}>
              {props.user.email}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => handleRedirect(props.from, "/dashboard/meetnow")}
          >
            <ListItemIcon>
              <VideoCameraFrontIcon fontSize="small" />
            </ListItemIcon>
            Meetnow
          </MenuItem>
          <MenuItem
            onClick={() => handleRedirect(props.from, "/dashboard/profile")}
          >
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={() => handleRedirect(props.from, "/dashboard")}>
            <ListItemIcon>
              <DashboardIcon fontSize="small" />
            </ListItemIcon>
            Dashboard
          </MenuItem>
          {props.from === "room" ? (
            <>
              <Divider />
              <MenuItem
                onClick={() =>
                  handleRedirect(props.from, "/dashboard/help?tab=9")
                }
              >
                <ListItemIcon>
                  <HelpCenterIcon fontSize="small" />
                </ListItemIcon>
                Help
              </MenuItem>
            </>
          ) : null}
        </span>
      ) : null}
      {props.authenticated && props.from !== "room" && (
        <span>
          {props.user && props.user.role === "admin" ? (
            <MenuItem
              onClick={() =>
                handleRedirect(
                  props.from,
                  `/cf/${props.user.uuid}/${props.user.profileId}/r`
                )
              }
            >
              <ListItemIcon>
                <MeetingRoomIcon fontSize="small" />
              </ListItemIcon>
              Personal Room
            </MenuItem>
          ) : null}
          <Divider />
          <MenuItem
            onClick={() => handleRedirect(props.from, "/dashboard/help")}
          >
            <ListItemIcon>
              <HelpCenterIcon fontSize="small" />
            </ListItemIcon>
            Help
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleRedirect(props.from, "/dashboard/support?tab=1")
            }
          >
            <ListItemIcon>
              <BugReportIcon fontSize="small" />
            </ListItemIcon>
            Report
          </MenuItem>
          <MenuItem onClick={() => handleRedirect(props.from, "/logout")}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </span>
      )}

      {!props.authenticated ? (
        <span>
          <MenuItem onClick={() => handleRedirect(props.from, "/login")}>
            <ListItemIcon>
              <LoginIcon fontSize="small" />
            </ListItemIcon>
            Login
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleRedirect(props.from, "/faq")}>
            <ListItemIcon>
              <HelpCenterIcon fontSize="small" />
            </ListItemIcon>
            FAQ
          </MenuItem>
          {/* <MenuItem
            onClick={() => handleRedirect(props.from, "/support?tab=1")}
          >
            <ListItemIcon>
              <BugReportIcon fontSize="small" />
            </ListItemIcon>
            Report
          </MenuItem> */}
        </span>
      ) : null}
    </Menu>
  );
  const toggleFullScreen = () => {
    const mainConfElement = document.getElementById("mainConfComponent");
    fscreen.addEventListener("fullscreenchange", () => {
      console.log("Full screen closed");
      if (!fscreen.fullscreenElement) {
        // document.exitFullscreen();
        setFullScreenStatus(false);
      }
      // setFullScreenStatus(false);
    });
    if (fullScreen) {
      if (fscreen.fullscreenElement) {
        fscreen.exitFullscreen();
      }
      setFullScreenStatus(false);
    } else {
      fscreen.requestFullscreen(mainConfElement);
      setFullScreenStatus(true);
    }
  };
  return (
    // <ClickAwayListener onClickAway={handleMenuClose}>
    <div className={styles.nav}>
      <AppBar
        position="fixed"
        enableColorOnDark
        sx={
          props.from === "room"
            ? null
            : { zIndex: (theme) => theme.zIndex.drawer + 1 }
        }
        // color={"primary"}
        // style={props.from==='room'?{height:'55px'}:null}
      >
        <Toolbar>
          <Box
            // component="Avatar"
            sx={{
              display:
                props.from === "room" ? { xs: "none", sm: "block" } : "block",
            }}
          >
            <Avatar className={classes.avatar}>
              <img
                // className={linkStyle}
                src={logo}
                width="25px"
                alt="Centedge logo"
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={
                  matches
                    ? () => dispatch(setMenu(!mobileMenu))
                    : () => handleRedirect(props.from, "/")
                }
              />
            </Avatar>

            {/* <Link className={linkStyle} to="/"> 
                            <img src='images/favicon.png'  width='25px' alt='brand logo' />
                            </Link> */}
          </Box>
          {/* <Typography  variant="h6" noWrap>
            Centedge
          </Typography> */}

          <div className={styles.flexGrow}>
            <div className={classes.toolBarLeft}>
              {props.from === "room" ? (
                <Tooltip title={"Time since room start"} placement="top-end">
                  <Chip
                    style={{ color: "#ffffff" }}
                    variant="outlined"
                    avatar={
                      <Avatar>
                        <AccessTimeIcon
                          style={{ backgroundColor: "#ffffff" }}
                        />
                      </Avatar>
                    }
                    label={`${hour}:${minute}:${second}`}
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
          <div className={styles.flexGrow}>
            <div className={classes.toolBar}>
              {props.from === "room" ? (
                <>
                  {participantType === MSG_SENDER_TYPE_MODERATOR ? (
                    <Tooltip
                      title={`Room ${
                        roomLockStatus.status ? "Locked" : "Unlocked"
                      } ${
                        roomLockStatus.status
                          ? " by " + roomLockStatus?.lockedByModName
                          : ""
                      } ${
                        roomLockStatus.status && roomLockStatus.peersWaiting > 0
                          ? ", Users in waiting room: " +
                            roomLockStatus.peersWaiting
                          : ""
                      }`}
                      placement="top-end"
                    >
                      <IconButton
                        style={{ color: "#ffffff", marginTop: "15px" }}
                        className={styles.theme}
                        onClick={() =>
                          props.roomClient.lockUnlockRoom(
                            !roomLockStatus.status
                          )
                        }
                        size="large"
                      >
                        {roomLockStatus.status ? (
                          roomLockStatus.peersWaiting > 0 ? (
                            <Badge
                              badgeContent={roomLockStatus.peersWaiting}
                              color="secondary"
                            >
                              <LockIcon />
                            </Badge>
                          ) : (
                            <LockIcon />
                          )
                        ) : (
                          <LockOpenIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {fscreen.fullscreenEnabled &&
                  browser.platform.type === "desktop" ? (
                    <Tooltip title={"Full Screen"} placement="top-end">
                      <IconButton
                        style={{ color: "#ffffff", marginTop: "15px" }}
                        className={styles.theme}
                        onClick={() => toggleFullScreen()}
                        size="large"
                      >
                        {fullScreen ? (
                          <FullscreenIconExit />
                        ) : (
                          <FullscreenIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : null}

                  <Tooltip title={"Settings"} placement="top-end">
                    <IconButton
                      style={{ color: "#ffffff", marginTop: "15px" }}
                      className={styles.theme}
                      onClick={() => {
                        if (fullScreen) toggleFullScreen();
                        dispatch(landingPageSetting(true));
                      }}
                      size="large"
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
              {props.from === "room" ? (
                <Tooltip title={"Copy room link to share"} placement="top-end">
                  <IconButton
                    style={{ color: "#ffffff", marginTop: "15px" }}
                    className={styles.theme}
                    onClick={props.copyLink}
                    size="large"
                  >
                    <InsertLinkIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              {!props.authenticated ? (
                <Button
                  color="inherit"
                  onClick={() => handleRedirect(props.from, "/registration")}
                >
                  Sign up
                </Button>
              ) : null}
            </div>
          </div>

          {/* <Tooltip title={"Light / Dark mode"} placement="top-end">
            <IconButton
              style={
                props.from === "room"
                  ? { marginTop: "15px" }
                  : { color: "#ffffff", marginTop: "15px" }
              }
              className={styles.theme}
              onClick={switchTheme}
              size="large"
            >
              <i className="material-icons">invert_colors</i>
            </IconButton>
          </Tooltip> */}
          {/* <IconButton
                        style={{color:'#ffffff'}}
                            className={styles.theme}
                            
                            onClick={()=>{history.push('/cf')}}
                        >
                            <VideoCallIcon />
                        </IconButton> */}
          <Box>
            <IconButton
              edge="end"
              className={styles.theme}
              style={{ color: "#ffffff", marginTop: "15px" }}
              aria-label="account of current user"
              aria-controls={isMenuOpen ? menuId : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? "true" : undefined}
              onClick={handleProfileMenuOpen}
              size="large"
            >
              <AccountCircle />
            </IconButton>
            {renderMenu}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
    // </ClickAwayListener>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.connection.user,
    callStartTime: state.conf.callStartTime,
    authenticated: state.connection.authenticated,
    theme: state.connection.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTheme: (theme) => {
      dispatch(setTheme(theme));
    },
  };
};

export default withRoomContext(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
