import {
  LANDING_PAGE_SETING_STATUS,
  tabs,
  LANDING_PAGE_HOVER_MENU,
} from "../actions/types";

// const initialstate = {}

export const openSettings = (state = false, action) => {
  switch (action.type) {
    case LANDING_PAGE_SETING_STATUS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export const verticalTab = (
  state = { number: 0, setDefault: false },
  action
) => {
  switch (action.type) {
    case tabs.VERTICAL_TAB: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export const horizontalTab = (
  state = { number: 0, setDefault: false },
  action
) => {
  switch (action.type) {
    case tabs.HORIZONTAL_TAB: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

// landing page hover menues
export const hoverMenu = (state = { open: false, hoverType: "" }, action) => {
  switch (action.type) {
    case LANDING_PAGE_HOVER_MENU: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
