import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import urlCall from "../../utils/urlCall";
import { URL_POST, URL_ROOM_SETTING } from "../../utils/constants";
import ConsoleHelper from "../../utils/consoleHelper";

const ContactSupport = (props) => {
  const [supportInfo, setSupportInfo] = useState({});
  const { siteSetting, roomId, from, uuid } = props;
  useEffect(() => {
    async function fetchData() {
      if (roomId && from === "landing") {
        ConsoleHelper(
          "ContactSupport Going to fetch support info for room",
          props.roomId
        );
        let query = { roomId, uuid };
        const res = await urlCall(
          `${URL_ROOM_SETTING}/fetchSupportInfo`,
          URL_POST,
          query
        );
        // console.log("support info", res.supportInfo);
        if (res && res.success) {
          // console.log("support info", res.supportInfo);
          setSupportInfo(res.supportInfo);
        }
      } else {
        // console.log(
        //   "Going to set support info from sitesetting",
        //   props.siteSetting
        // );
        setSupportInfo({
          organizationEmail: props.siteSetting.organizationEmail,
          organizationPhone: props.siteSetting.organizationPhone,
        });
      }
    }
    fetchData();
  }, [siteSetting]);

  return (
    <Grid
      container
      justifyContent={"center"}
      direction="column"
      alignItems="center"
    >
      {/* <Grid item> */}
      {supportInfo &&
      (supportInfo.organizationEmail || supportInfo.organizationPhone) ? (
        <Typography variant="body2" align="center">
          For Admin support:
          <br />
          {supportInfo && supportInfo.organizationEmail ? (
            <>
              <IconButton style={{ cursor: "default" }}>
                <AlternateEmailIcon />
              </IconButton>
              {`${supportInfo.organizationEmail}`}
            </>
          ) : null}
          {supportInfo && supportInfo.organizationPhone ? (
            <>
              <IconButton style={{ cursor: "default" }}>
                <ContactPhoneIcon />
              </IconButton>
              {`${supportInfo.organizationPhone}`}
              <br />
            </>
          ) : null}
        </Typography>
      ) : null}
      Centedge Email support:
      <br />
      <Typography variant="body2" align="center">
        <IconButton style={{ cursor: "default" }}>
          <AlternateEmailIcon />
        </IconButton>
        {`support@centedge.io`}
      </Typography>
      {/* </Grid> */}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.connection.user,
  siteSetting: state.siteSetting.siteSetting,
});

export default connect(mapStateToProps, null)(ContactSupport);
