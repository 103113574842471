import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import ViewerCard from "./core/components/ViewerCard";
import BackgroundConfigCard from "./core/components/BackgroundConfigCard";
import { setVBDetails } from "../../../actions/confActions";
import {
  BackgroundConfig,
  backgroundImageUrls,
} from "./core/helpers/backgroundHelper";
import { SegmentationConfig } from "./core/helpers/segmentationHelper";
import { PostProcessingConfig } from "./core/helpers/postProcessingHelper";
import { SourceConfig, sourceImageUrls } from "./core/helpers/sourceHelper";
import useTFLite from "./core/hooks/useTFLite";
import useBodyPix from "./core/hooks/useBodyPix";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

type VbProps = {
  sourcePlayback: any;
  setLocalStream: any;
  localCam: any;
  vBDetails: BackgroundConfig;
};

function VirtualBackground(props: VbProps) {
  const dispatch = useDispatch();
  const [sourceConfig, setSourceConfig] = useState<SourceConfig>({
    type: "camera",
  });
  // const [backgroundConfig, setBackgroundConfig] = useState<BackgroundConfig>(
  //   props.vBDetails
  // );
  const backgroundConfig = useSelector((state: any) => state.conf.vBDetails);
  const setBackgroundConfig = (details: BackgroundConfig) => {
    dispatch(setVBDetails(details));
  };

  const [segmentationConfig, setSegmentationConfig] =
    useState<SegmentationConfig>({
      model: "meet",
      backend: "wasm",
      inputResolution: "256x144",
      pipeline: "webgl2",
      targetFps: 65, // 60 introduces fps drop and unstable fps on Chrome
      deferInputResizing: true,
    });
  const [postProcessingConfig, setPostProcessingConfig] =
    useState<PostProcessingConfig>({
      smoothSegmentationMask: true,
      jointBilateralFilter: { sigmaSpace: 1, sigmaColor: 0.1 },
      coverage: [0.5, 0.75],
      lightWrapping: 0.3,
      blendMode: "screen",
    });
  const { tflite, isSIMDSupported } = useTFLite(segmentationConfig);
  const bodyPix = useBodyPix();
  useEffect(() => {
    setSegmentationConfig((previousSegmentationConfig) => {
      if (previousSegmentationConfig.backend === "wasm" && isSIMDSupported) {
        return { ...previousSegmentationConfig, backend: "wasmSimd" };
      } else {
        return previousSegmentationConfig;
      }
    });
  }, [isSIMDSupported]);
  return (
    <>
      <ViewerCard
        sourceConfig={sourceConfig}
        backgroundConfig={backgroundConfig}
        segmentationConfig={segmentationConfig}
        postProcessingConfig={postProcessingConfig}
        setLocalStream={props.setLocalStream}
        localCam={props.localCam}
        bodyPix={bodyPix}
        tflite={tflite}
        sourcePlayback={props.sourcePlayback}
      />
      <BackgroundConfigCard
        config={backgroundConfig}
        onChange={setBackgroundConfig}
      />
      {/* <SourceConfigCard config={sourceConfig} onChange={setSourceConfig} /> */}

      {/* <SegmentationConfigCard
        config={segmentationConfig}
        isSIMDSupported={isSIMDSupported}
        onChange={setSegmentationConfig}
      /> */}
      {/* <PostProcessingConfigCard
        config={postProcessingConfig}
        pipeline={segmentationConfig.pipeline}
        onChange={setPostProcessingConfig}
      /> */}
    </>
  );
}

const MemoizedVirtualBackground = React.memo(VirtualBackground);
export default MemoizedVirtualBackground;
