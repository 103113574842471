import { SET_SITE_SETTING } from "../actions/types";

// const initialstate = {}
const initialstate = {
  siteSetting: {},
};

function siteSetting(state = initialstate, action) {
  // console.log("action = "+JSON.stringify(action));
  switch (action.type) {
    case SET_SITE_SETTING:
      //   console.log("inside reducer", action.payload);
      return { ...state, siteSetting: action.payload };
    default:
      return state;
  }
}

export default siteSetting;
