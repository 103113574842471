import {
    SET_CALL_RECORDS
    }
    from '../actions/types'

const initState = {
    stats:[]
}    

const callStatsReducer = (state = initState, action) => {
    switch (action.type) {
      case SET_CALL_RECORDS:
        return {
          ...state,
          stats: action.payload
        }
        default:
            return state
        }
      }
      
      export default callStatsReducer;