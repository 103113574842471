export type BackgroundConfig = {
  type: "none" | "blur" | "image";
  url?: string;
};

export const backgroundImageUrls = [
  "meeting-room-1280-1",
  "meeting-room-1280-2",
  "lobby-1280-1",
  "office-space-1280-1",
  "office-1280-1",
  "office-1280-2",
  "office-1280-3",
  "coworking-1280-1",
  "cafe-1280-1",
  "cafe-1280-2",
  "samos-1280-1",
  "coffee-shop-1280-1",
  "sky-2",
  "aurora-1",
  "nature-1280-1",
  "splashing-1280-1",
  "mountains-1280-1",
  "porch-691330_1280",
  "saxon-switzerland-539418_1280",
  "shibuyasky-4768679_1280",
].map((imageName) => `${process.env.PUBLIC_URL}/backgrounds/${imageName}.jpg`);
