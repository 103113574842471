import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { URL_CALL_SAVE_FEEDBACK, URL_POST } from "../../utils/constants";
import urlCall from "../../utils/urlCall";

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: theme.spacing(2),
    borderRadius: "25px",
    padding: "25px",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      width: "35vw",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      width: "85vw",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      width: "90vw",
    },
  },
  iconStyle: {
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.up("md")]: {
        fontSize: "80px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "64px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "48px",
      },
    },
  },
  // paper: {
  //   height: 140,
  //   width: 100,
  // },
  control: {
    padding: theme.spacing(2),
  },
}));

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const Feedback = (props) => {
  const [rating, setRating] = useState(4);
  const [value, setValue] = useState("");
  const classes = useStyles();
  const history = useNavigate();
  const { orgid } = useParams();
  const [search, setSearch] = useSearchParams();
  const cId = search.get("dir");
  const room = search.get("room");
  const name = search.get("name");
  const pwd = search.get("dwp");
  const proName = search.get("proName");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const rejoinRoom = () => {
    if (room) {
      let path = `/cf/${orgid}/${room}/r/`;
      if (pwd && proName) path = path + `?dwp=${pwd}&proName=${proName}`;
      if (pwd && !proName) path = path + `?dwp=${pwd}`;
      if (proName && !pwd) path = path + `?proName=${proName}`;
      history(path);
    } else history(`/`);
  };
  const handleSave = async () => {
    // console.log(`The rating is ${rating} and the feedback is ${value}`);
    let data = {
      id: cId,
      rating: rating,
      feedback: value,
      name,
      uuid: orgid,
    };
    let res1 = await urlCall(URL_CALL_SAVE_FEEDBACK, URL_POST, data);
    // console.log("res = "+JSON.stringify(res));
    if (res1 && res1.success) {
      setValue("");
      setRating(4);
      // props.setCallStats(res1);
      props.notification({
        type: "success",
        title: "Feeback saved!",
        message:
          "Thank you for providing your valuable feedback. Your feedback has successfully been saved!",
      });
      // console.log(res1)
    } else {
      setValue("");
      setRating(4);
      props.notification({
        type: "danger",
        title: "Feedback cannot be saved!",
        message: "Try again later!",
      });
      console.log("room settings not found!!");
    }
  };
  return (
    <div

    // style={{
    //   // backgroundColor: "#4343BB",
    //   width: "100%",
    //   height: "100vh",
    //   marginTop: "-68px",
    // }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Paper
            elevation={16}
            className={classes.paper}
            sx={{
              // padding: "30px",
              // borderRadius: "25px",
              marginTop: "68px",
              "& .MuiButton-root": {
                textTransform: "none",
                fontSize: "16px",
              },
            }}
          >
            {props.conf.roomClosedByModerator ? (
              <Box
                mt={3}
                mb={3}
                component="fieldset"
                borderColor="transparent"
                display="flex"
                justifyContent="center"
              >
                <Typography component="legend" align="center">
                  The room was closed by the room owner!
                </Typography>
              </Box>
            ) : null}
            <Box
              m={3}
              component="fieldset"
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              sx={{ borderRadius: "10px" }}
            >
              <Typography component="legend">
                Thank you for using Centedge Meetnow.
              </Typography>
              <Button
                style={{ margin: "8px 16px" }}
                variant="contained"
                color="primary"
                onClick={rejoinRoom}
              >
                Re-join room
              </Button>

              {props.authenticated ? (
                <Button
                  style={{ margin: "8px 16px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => history(`/dashboard`)}
                >
                  Go To Dashboard
                </Button>
              ) : (
                <Button
                  style={{ margin: "8px 16px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => history(`/`)}
                >
                  Go To Home
                </Button>
              )}
            </Box>

            <Box
              // component="fieldset"
              mb={3}
              mt={3}
              borderColor="transparent"
              display="flex"
              justifyContent="center"
            >
              <Box>
                <Typography component="legend">
                  How was your experience?
                </Typography>
                <Rating
                  name="customized-icons"
                  className={classes.iconStyle}
                  // defaultValue={2}
                  value={rating}
                  getLabelText={(value) => customIcons[value].label}
                  IconContainerComponent={IconContainer}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </Box>
            </Box>
            <Box m={1}>
              <TextField
                id="standard-multiline-flexible"
                label="We would love to hear your feedback."
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                value={value}
                onChange={handleChange}
              />
            </Box>
            <Box p={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Send
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  conf: state.conf,
  user: state.connection.user,
  authenticated: state.connection.authenticated,
  notification: state.connection.notification,
});
// export default Feedback;
export default connect(mapStateToProps, null)(Feedback);
