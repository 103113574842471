import { SET_CURRENT_ROOM_ID, SET_ROOM_SETTINGS,SET_CURRENT_ROOM_NAME } from "../actions/types"

const initialstate = {
    roomSettings: {},
    currentRoomID: ""
}

export default function (state = initialstate, action) {
    // console.trace("called");
    // console.log("action = "+JSON.stringify(action));
    // console.log("action.payload = "+JSON.stringify(action.payload));
    switch (action.type) {
        case SET_ROOM_SETTINGS:
            return {...state ,roomSettings: action.payload};
        case SET_CURRENT_ROOM_ID:
            return { ...state, currentRoomID: action.payload};
        case SET_CURRENT_ROOM_NAME:
            let updatedRoomSettings =  { ...state.roomSettings};
            updatedRoomSettings[action.payload.roomId].room = action.payload.randomRoomName;
                return {...state ,roomSettings: updatedRoomSettings};
            
        default:
        return state;
    }
}