import { SET_PAYMENTS } from "../actions/types";

// const initialstate = {}
const initialstate = {
  payments: [],
};

const paymentReducer = (state = initialstate, action) => {
  // console.log("action = "+JSON.stringify(action));
  switch (action.type) {
    case SET_PAYMENTS:
      return { ...state, payments: action.payload };
    default:
      return state;
  }
};
export default paymentReducer;
