import {
  SET_URL,
  SET_AUTH,
  SET_NOTIFICATIONS,
  SET_USER,
  SET_PAYPAL,
  SET_THEME,
  SET_CONF_BG_COLOR,
  SET_MOBILE_MENU,
  SET_FULL_SCREEN,
} from "./types";

export const setUrl = (url) => {
  return {
    type: SET_URL,
    url,
  };
};

export const setAuth = (authenticated) => {
  return {
    type: SET_AUTH,
    authenticated,
  };
};

export const setNotifications = (notification) => {
  return {
    type: SET_NOTIFICATIONS,
    notification,
  };
};

export const setUser = (user) => {
  return {
    type: SET_USER,
    user,
  };
};

export const setPaypal = (paypal) => {
  return {
    type: SET_PAYPAL,
    paypal,
  };
};

export const setTheme = (theme) => {
  return {
    type: SET_THEME,
    theme,
  };
};
export const setConfBGColor = (colorCode) => {
  return {
    type: SET_CONF_BG_COLOR,
    colorCode,
  };
};

export const setMenu = (status) => {
  return {
    type: SET_MOBILE_MENU,
    status,
  };
};

export const setFullScreen = (fullScreen) => {
  return {
    type: SET_FULL_SCREEN,
    fullScreen,
  };
};
