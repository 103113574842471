import { JOIN_PAGE_SETING_STATUS } from "../actions/types";

// const initialstate = {}

export default function (state = false, action) {
  switch (action.type) {
    case JOIN_PAGE_SETING_STATUS: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
