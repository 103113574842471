import React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const UserFeedbackSnackbar=(props)=> {
  const classes = useStyles();
//   const [open, setOpen] = React.useState(false);

//   const handleClick = () => {
//     setOpen(true);
//   };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    // console.log('function call for closing the snackbar')
    props.handleClose();
  };

  return (
    
      <Snackbar open={props.open} autoHideDuration={props.autoHideDuration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.severity}>
          {props.message}
        </Alert>
      </Snackbar>
     
  );
}
export default UserFeedbackSnackbar;