import Bowser from "bowser";

import {
  SET_URL,
  SET_AUTH,
  SET_NOTIFICATIONS,
  SET_USER,
  SET_PAYPAL,
  SET_THEME,
  SET_CONF_BG_COLOR,
  SET_MOBILE_MENU,
  SET_FULL_SCREEN,
} from "../actions/types";

const initState = {
  user: null,
  authenticated: false,
  notification: null,
  paypal: null,
  theme: "light",
  confBgColor: "#4343bb",
  mobileMenu: false,
  fullScreen: false,
  browser: Bowser.parse(window.navigator.userAgent),
};

const connectionReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_URL:
      return {
        ...state,
        url: action.url,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notification: action.notification,
      };
    case SET_AUTH:
      return {
        ...state,
        authenticated: action.authenticated,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_PAYPAL:
      return {
        ...state,
        paypal: action.paypal,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case SET_CONF_BG_COLOR:
      return {
        ...state,
        confBgColor: action.colorCode,
      };
    case SET_MOBILE_MENU:
      return {
        ...state,
        mobileMenu: action.status,
      };
    case SET_FULL_SCREEN:
      return {
        ...state,
        fullScreen: action.fullScreen,
      };
    default:
      return state;
  }
};

export default connectionReducer;
