import { combineReducers } from "redux";
// import kurentoReducer from "./kurentoAVCallReducer";
// import kurentoStreamingReducer from "./kurentoStreamingReducer";
import confReducer from "./confReducer";
import errorReducer from "./errorReducer";
import connectionReducer from "./connectionReducer";
import presenceReducer from "./presenceReducer";
import siteSettingReducer from "./siteSettingReducer";
import roomSettingReducer from "./roomSettingReducer";
import callStatsReducer from "./callStatsReducer";
import paymentReducer from "./paymentReducer";
import { breakoutSection } from "./breacoutSectionReducer";
import joinPageSetting from "./joinPageSettingReducer";
import {
  openSettings,
  verticalTab,
  horizontalTab,
  hoverMenu,
} from "./landingPageSettingReducer";
import confOptionsReducer from "./confOptionsReducer";
import orgUsersReducer from "./orgUsersReducer";

export default combineReducers({
  // kurento:kurentoReducer,
  // kurentoStreaming:kurentoStreamingReducer,
  conf: confReducer,
  error: errorReducer,
  connection: connectionReducer,
  presence: presenceReducer,
  siteSetting: siteSettingReducer,
  roomSetting: roomSettingReducer,
  callStats: callStatsReducer,
  payment: paymentReducer,
  confOptions: confOptionsReducer,
  orgUsers: orgUsersReducer,
  // braekout section
  breakoutSection,
  // join page settings
  joinPageSetting,
  // landing page settings
  openSettings,
  verticalTab,
  horizontalTab,
  hoverMenu,
});
