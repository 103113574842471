import { BREAKOUT_SECTION } from "../actions/types";

export const breakoutSection = (state = false, action) => {
  switch (action.type) {
    case BREAKOUT_SECTION: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
