import { SET_USERS, SET_DELETED_USERS } from "../actions/types";

// const initialstate = {}
const initialstate = {
  users: [],
  deletedUsers: [],
};

const orgUsersReducer = (state = initialstate, action) => {
  // console.log("action = "+JSON.stringify(action));
  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.payload };
    case SET_DELETED_USERS:
      return { ...state, deletedUsers: action.payload };
    default:
      return state;
  }
};
export default orgUsersReducer;
