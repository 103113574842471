import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { BodyPix } from "@tensorflow-models/body-pix";
import { useEffect } from "react";
import { BackgroundConfig } from "../helpers/backgroundHelper";
import { PostProcessingConfig } from "../helpers/postProcessingHelper";
import { SegmentationConfig } from "../helpers/segmentationHelper";
import { SourcePlayback } from "../helpers/sourceHelper";
import useRenderingPipeline from "../hooks/useRenderingPipeline";
import { TFLite } from "../hooks/useTFLite";

type OutputViewerProps = {
  sourcePlayback: SourcePlayback;
  backgroundConfig: BackgroundConfig;
  setLocalStreamVb: any;
  segmentationConfig: SegmentationConfig;
  postProcessingConfig: PostProcessingConfig;
  bodyPix: BodyPix;
  tflite: TFLite;
};

function OutputViewer(props: OutputViewerProps) {
  const classes = useStyles();
  const {
    pipeline,
    backgroundImageRef,
    canvasRef,
    fps,
    durations: [resizingDuration, inferenceDuration, postProcessingDuration],
  } = useRenderingPipeline(
    props.sourcePlayback,
    props.backgroundConfig,
    props.segmentationConfig,
    props.bodyPix,
    props.tflite
  );
  const { backgroundConfig, setLocalStreamVb, sourcePlayback } = props;

  useEffect(() => {
    if (pipeline) {
      pipeline.updatePostProcessingConfig(props.postProcessingConfig);
    }
  }, [pipeline, props.postProcessingConfig]);

  useEffect(() => {
    // const peerConnection = new RTCPeerConnection();
    // const signalingChannel = new BroadcastChannel("signaling-channel");

    const localStream = canvasRef.current.captureStream();
    setLocalStreamVb({
      type: backgroundConfig.type,
      url: backgroundConfig.url,
      stream: localStream,
    });
    // localStream.getTracks().forEach((track) => {
    //   peerConnection.addTrack(track, localStream);
    // });

    // peerConnection.onicecandidate = (event) => {
    //   if (event.candidate) {
    //     console.log("Sending callee ICE candidate");
    //     signalingChannel.postMessage(
    //       JSON.stringify({ iceCandidate: event.candidate })
    //     );
    //   }
    // };

    // signalingChannel.onmessage = async (event) => {
    //   const message = JSON.parse(event.data);

    //   if (message.offer) {
    //     console.log("Received offer");
    //     const remoteDesc = new RTCSessionDescription(message.offer);
    //     await peerConnection.setRemoteDescription(remoteDesc);

    //     console.log("Sending answer");
    //     const answer = await peerConnection.createAnswer();
    //     await peerConnection.setLocalDescription(answer);
    //     signalingChannel.postMessage(JSON.stringify({ answer }));
    //   } else if (message.iceCandidate) {
    //     console.log("Received caller ICE candidate");
    //     await peerConnection.addIceCandidate(message.iceCandidate);
    //   }
    // };

    return () => {
      // peerConnection.close();
      // signalingChannel.close();
    };
  }, [canvasRef, backgroundConfig, sourcePlayback]);

  // const statDetails = [
  //   `resizing ${resizingDuration}ms`,
  //   `inference ${inferenceDuration}ms`,
  //   `post-processing ${postProcessingDuration}ms`,
  // ];
  // const stats = `${Math.round(fps)} fps (${statDetails.join(", ")})`;

  return (
    <>
      {props.backgroundConfig.type === "image" && (
        <img
          ref={backgroundImageRef}
          className={classes.render}
          src={props.backgroundConfig.url}
          alt=""
          hidden={props.segmentationConfig.pipeline === "webgl2"}
        />
      )}
      <canvas
        key={props.segmentationConfig.pipeline}
        ref={canvasRef}
        className={classes.render}
        width={props.sourcePlayback.width}
        height={props.sourcePlayback.height}
      />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // root: {
    //   flex: 1,
    //   position: "relative",
    // },
    render: {
      // position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "8px",
      // zIndex: 991,
    },
  })
);

export default OutputViewer;
