import React, { Component } from "react";
import { Route, Redirect, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  if (authenticated) {
    return <Component />;
  } else {
    return <Navigate to="/login" />;
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.bool,
};
const mapStateToProps = (state) => {
  return {
    authenticated: state.connection.authenticated,
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
