import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

const Loading = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        // style={{ color: "#fff" }}
      >
        Loading....
      </Typography>
      <CircularProgress />
    </Grid>
  );
};

export default Loading;
