import Box from "@mui/material/Box";
// import CardContent from "@mui/material/CardContent";
import { createStyles, makeStyles } from "@mui/styles";
import { Drawer, IconButton, Theme } from "@mui/material";
// import Typography from "@mui/material/Typography";
// import BlockIcon from "@mui/icons-material/Block";
import CloseIcon from "@mui/icons-material/Close";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import ImageButton from "../../shared/components/ImageButton";
import SelectionIconButton from "../../shared/components/SelectionIconButton";
import { setVBItemsStatus } from "../../../../../actions/confActions";
import {
  BackgroundConfig,
  backgroundImageUrls,
} from "../helpers/backgroundHelper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

type BackgroundConfigCardProps = {
  config: BackgroundConfig;
  onChange: (config: BackgroundConfig) => void;
};
const drawerWidth = 170;
function BackgroundConfigCard(props: BackgroundConfigCardProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const vbItems = useSelector((state: any) => state.conf.vbItems);
  return (
    <>
      <Drawer
        variant="persistent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            marginTop: "64px",
          },
        }}
        open={vbItems}
      >
        <Box className={classes.root}>
          <IconButton
            color="primary"
            style={{ position: "absolute", right: 5, top: 5 }}
            onClick={() => dispatch(setVBItemsStatus(false))}
          >
            <CloseIcon />
          </IconButton>
          <Box
            style={{ display: "flex", justifyContent: "center", margin: "8px" }}
          >
            {/* <SelectionIconButton
              active={props.config.type === "none"}
              onClick={() => props.onChange({ type: "none" })}
            >
              <BlockIcon />
            </SelectionIconButton> */}

            <SelectionIconButton
              active={props.config.type === "blur"}
              onClick={() => props.onChange({ type: "blur" })}
            >
              <BlurOnIcon />
            </SelectionIconButton>
          </Box>
          <br />
          {backgroundImageUrls.map((imageUrl) => (
            <ImageButton
              key={imageUrl}
              imageUrl={imageUrl}
              active={imageUrl === props.config.url}
              onClick={() => props.onChange({ type: "image", url: imageUrl })}
            />
          ))}
        </Box>
      </Drawer>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      position: "relative",
      // marginTop: "68px",
      padding: "32px 4px",
      // backgroundColor:"#e5e5e5"
      // position: "fixed",
      // top: "88px",
    },
  })
);

export default BackgroundConfigCard;
