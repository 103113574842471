import {
  LANDING_PAGE_SETING_STATUS,
  tabs,
  LANDING_PAGE_HOVER_MENU,
} from "./types";

export const landingPageSetting = (open) => {
  return {
    type: LANDING_PAGE_SETING_STATUS,
    payload: open,
  };
};

export const verticalTab = (number, setDefault = false) => {
  return {
    type: tabs.VERTICAL_TAB,
    payload: { number, setDefault },
  };
};

export const horizontalTab = (number, setDefault = false) => {
  return {
    type: tabs.HORIZONTAL_TAB,
    payload: { number, setDefault },
  };
};

// hover menus
export const openHoverMenu = (open, hoverType) => {
  return {
    type: LANDING_PAGE_HOVER_MENU,
    payload: { open, hoverType },
  };
};
